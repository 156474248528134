<template>
    <v-responsive v-if="board">
        <v-card tile elevation="0">
            <v-divider/>
            <v-card-title class="subtitle-2">
                <v-layout align-center fill-height wrap>
                    <v-flex xs12 md6 shrink>
                        제목: {{ board.subject }}
                    </v-flex>
                    <!-- <v-spacer /> -->
                    <v-flex class="text-align mt-2 mt-md-0" xs12 md6 shrink>작성일자: {{ $dayjs(board.createdAt).format("YYYY-MM-DD") }}</v-flex>
                </v-layout>
            </v-card-title>
            <v-card-subtitle class="subtitle-2">
                <v-layout wrap>
                    <v-flex xs12 md6 shrink class="mt-2">
                        <span>작성자: {{ board.writer.name }}</span>
                    </v-flex>
                    <v-spacer/>
                    <v-flex xs12 md6 shrink class="mt-2 text-align">
                        <span>조회수: {{ board.viewCount }}</span>
                    </v-flex>
                </v-layout>
            </v-card-subtitle>

            <template v-if="board.images.some(image => !!image)">
                <v-divider/>
                <v-card-text class="body-1">
                    <template v-for="(image, index) in board.images">
                        <v-btn color="primary" outlined :key="index" @click="download(image, `첨부이미지_${index+1}`)"><v-icon class="mr-2">{{ mdiDownload }}</v-icon>첨부파일 다운로드</v-btn>
                    </template>
                </v-card-text>
            </template>

            <v-divider/>
            <v-card-text class="body-1">
                <v-responsive min-height="400" class="overflow-scroll">
                    <p v-html="board.content"></p>
                </v-responsive>
            </v-card-text>

            <template v-if="board.reply">
                <v-divider/>
                <v-card-text class="body-1">
                    <div class="mb-2"><v-icon>{{ mdiArrowRightBottom }}</v-icon> 관리자 답변</div>
                    <v-responsive min-height="400" class="overflow-scroll">
                        <p v-html="board.reply"></p>
                    </v-responsive>
                </v-card-text>
            </template>

            <v-divider/>
            <v-card-actions class="grey lighten-5">
                <v-btn outlined @click="back"><v-icon>{{ mdiChevronLeft }}</v-icon>뒤로가기</v-btn>
            </v-card-actions>
        </v-card>
    </v-responsive>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

import { mdiChevronLeft, mdiArrowRightBottom, mdiDownload } from "@mdi/js";
export default {
    props: {
        board: { type: Object }
    },
    data(){
        return {
            mdiDownload,
            mdiChevronLeft,
            mdiArrowRightBottom
        }
    },
    methods: {
        back(){
            this.$router.go(-1);
        },
        download(path = "", rename){
            api.getResource(path).then(file => download(file, rename + path.substr(path.lastIndexOf('.'))) )
        }
    }
}
</script>
<style>

@media (min-width:1024px) {
    .text-align {text-align:right;}
}
</style>
