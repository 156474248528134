var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('v-responsive', [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-divider'), _c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_c('v-layout', {
    attrs: {
      "align-center": "",
      "fill-height": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "shrink": ""
    }
  }, [_vm._v(" 제목: " + _vm._s(_vm.board.subject) + " ")]), _c('v-flex', {
    staticClass: "text-align mt-2 mt-md-0",
    attrs: {
      "xs12": "",
      "md6": "",
      "shrink": ""
    }
  }, [_vm._v("작성일자: " + _vm._s(_vm.$dayjs(_vm.board.createdAt).format("YYYY-MM-DD")))])], 1)], 1), _c('v-card-subtitle', {
    staticClass: "subtitle-2"
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "mt-2",
    attrs: {
      "xs12": "",
      "md6": "",
      "shrink": ""
    }
  }, [_c('span', [_vm._v("작성자: " + _vm._s(_vm.board.writer.name))])]), _c('v-spacer'), _c('v-flex', {
    staticClass: "mt-2 text-align",
    attrs: {
      "xs12": "",
      "md6": "",
      "shrink": ""
    }
  }, [_c('span', [_vm._v("조회수: " + _vm._s(_vm.board.viewCount))])])], 1)], 1), _vm.board.images.some(function (image) {
    return !!image;
  }) ? [_c('v-divider'), _c('v-card-text', {
    staticClass: "body-1"
  }, [_vm._l(_vm.board.images, function (image, index) {
    return [_c('v-btn', {
      key: index,
      attrs: {
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.download(image, `첨부이미지_${index + 1}`);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(_vm.mdiDownload))]), _vm._v("첨부파일 다운로드")], 1)];
  })], 2)] : _vm._e(), _c('v-divider'), _c('v-card-text', {
    staticClass: "body-1"
  }, [_c('v-responsive', {
    staticClass: "overflow-scroll",
    attrs: {
      "min-height": "400"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])], 1), _vm.board.reply ? [_c('v-divider'), _c('v-card-text', {
    staticClass: "body-1"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiArrowRightBottom))]), _vm._v(" 관리자 답변")], 1), _c('v-responsive', {
    staticClass: "overflow-scroll",
    attrs: {
      "min-height": "400"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.reply)
    }
  })])], 1)] : _vm._e(), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-5"
  }, [_c('v-btn', {
    attrs: {
      "outlined": ""
    },
    on: {
      "click": _vm.back
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiChevronLeft))]), _vm._v("뒤로가기")], 1)], 1)], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }